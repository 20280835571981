import React from 'react';
import { navigate } from 'gatsby';

const handleClick = event => {
  const href = event.currentTarget.getAttribute('href');
  if (!/^http/.test(href)) {
    event.preventDefault();
    navigate(href);
  }
};

export default function withLink (InputComponent) {
  return class extends React.Component {
    render () {
      return <InputComponent {...this.props} onClick={handleClick} />;
    }
  };
}
