import React, { useState } from 'react';
import classnames from 'classnames';
import MainMenu from '@terralego/core/components/MainMenu';
import withDeviceSize from '@terralego/core/hoc/withDeviceSize';
import { Link } from 'gatsby';

import logo from '../../images/blason.svg';

import './styles.scss';

const isMenuItemActive = ({ isPartiallyCurrent }) => isPartiallyCurrent && { className: 'header-link  active' };

const getLinkProps = link => (
  !link.startsWith('http') && {
    link: {
      component: Link,
      linkProps: {
        hrefAttribute: 'to',
        getProps: isMenuItemActive,
      },
    },
  }
);

const nav = {
  navHeader: {
    id: 'welcome',
    label: 'Accueil',
    href: 'https://www.sud-foncier-eco.fr/',
    icon: logo,
  },
  navItems: [
    [
      {
        id: 'nav-visualiser',
        label: 'Visualiser',
        href: 'https://visu.sud-foncier-eco.fr/visualiser/rechercher',
        icon: 'layers',
      },
      {
        id: 'nav-analyser',
        label: 'Analyser',
        href: 'https://visu.sud-foncier-eco.fr/visualiser/analyse',
        icon: 'heatmap',
      },
      {
        id: 'nav-story',
        label: 'Découvrir les espaces d\'activités',
        href: 'https://visu.sud-foncier-eco.fr/visualiser/story',
        icon: 'presentation',
      },
    ],
    [
      {
        id: 'nav-lister',
        label: 'Lister les espaces d\'activités',
        href: '/espaces-d-activites',
        icon: 'properties',
        ...getLinkProps('/espaces-d-activites'),
      },
      {
        id: 'nav-explorer',
        label: 'Explorer / Filtrer les espaces d\'activités',
        href: '/explorer',
        icon: 'map-create',
        ...getLinkProps('/explorer'),
      },
    ],
    [],
  ],
};

/* eslint-disable react/no-array-index-key */
export const Header = ({ className, isMobileSized }) => {
  const [isHeaderOpen, toggleHeader] = useState(false);

  return (
  // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      className={classnames(
        className,
        'main__header',
        { 'main__header--mobile': isMobileSized },
        { 'main__header--mobile--open': isMobileSized && isHeaderOpen },
      )}
      onClick={() => toggleHeader(true)}
      role="button"
      tabIndex="-1"
    >
      <MainMenu className="main__navbar" {...nav} />
      {(isMobileSized && !isHeaderOpen)
        // eslint-disable-next-line
        && <div className="main__header__target" role="button" tabIndex="-1" onClick={() => toggleHeader(false)} />}
    </div>
  );
};

export default withDeviceSize('isMobileSized')(Header);
