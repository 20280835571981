import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { StaticQuery, graphql } from 'gatsby';
import classnames from 'classnames';

import Header from './Header';

import 'normalize.css/normalize.css';
import '@blueprintjs/core/lib/css/blueprint.css';

import styles from './layout.module.scss';
import TopBar from './TopBar/TopBar';

const Layout = ({
  topBarProps = {},
  data,
  children,
  full = false,
}) => (
  <div className={styles.globalWrapper}>
    <Helmet
      title={data.site.siteMetadata.title}
      meta={[{ name: 'description', content: 'Sample' }]}
    >
      <html lang="fr" />
    </Helmet>

    <Header />
    <div
      style={{ zIndex: 0 }}
      className={styles.mainWrapper}
      tabIndex={-1}
    >
      <TopBar {...topBarProps} />
      <div className={classnames({
        [styles.main]: true,
        [styles.full]: full,
      })}
      >
        {children}
      </div>
    </div>
  </div>
);

export default props => (
  <StaticQuery
    query={graphql`query SiteTitleQuery { site { siteMetadata { title } } }`}
    render={data => (<Layout {...props} data={data} />)}
  />
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};
