import React from 'react';
import classnames from 'classnames';
import { AnchorButton, Classes, Navbar, NavbarHeading, Intent, Tooltip } from '@blueprintjs/core';
import AppName from '../AppName';
import styles from './topBar.module.scss';
import withLink from '../../hoc/withLink';
import logo from '../../images/blason.svg';

const AnchorButtonLink = withLink(AnchorButton);

const atLink = process.env.GATSBY_AIRTABLE_LINK || 'https://airtable.com';

const TopBar = ({ className, pageTitle, returnList = false, buttonText, href }) => (
  <>
    <NavbarHeading className={styles.navbar__heading}>
      <img className={styles.mainLogo} src={logo} alt="" />
      <p className={styles.mainLogo}>SUD Foncier éco</p>
    </NavbarHeading>
    <Navbar className={classnames(className, Classes.DARK, styles.topBar)}>
      <AppName />
      <div className={styles.topBar__pageTitle}>
        {returnList && (
        <AnchorButtonLink
          className={styles.backButton}
          text={buttonText}
          href={href}
          intent="primary"
        />
        )}
        <h5 className={styles.topBar__pageTitle__text}>{pageTitle}</h5>
      </div>
      <div style={{ marginLeft: 'auto' }}>
        <Tooltip
          content="Cet espace vous permet d'ajouter et de modifier des éléments sur la structure de l'ensemble des fiches"
        >
          <AnchorButton
            href={atLink}
            rel="nofollow"
            target="_blank"
            intent={Intent.PRIMARY}
          >
            Modification des fiches
          </AnchorButton>
        </Tooltip>
      </div>
    </Navbar>
  </>
);

export default TopBar;
